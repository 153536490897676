<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px">
          <a-button
            type="primary"
            @click="$router.push($route.path + '/add')"
            v-if="$getPermission($route.path + '/add')"
          >发起审批</a-button>
        </a-space>
      </template>
    </Pane>

    <a-card class="container">
      <div class="card">
        <div class="item">
          <div class="title">
            <span>本年已通过(万)</span>
            <span class="extra">
              <MonthList
                title="本年已通过"
                :list="Array.isArray(statistic.approvedAmountCurrentYearDeptList) ? statistic.approvedAmountCurrentYearDeptList : []"
                @search="onSearch"
              />
            </span>
          </div>
          <div
            class="value"
          >{{ typeof statistic.approvedAmountCurrentYear === 'number' ? statistic.approvedAmountCurrentYear.toLocaleString() : '--' }}</div>
        </div>

        <div class="item">
          <div class="title">
            <span>累计已通过(万)</span>
            <span class="extra">
              <MonthList
                title="累计已通过"
                :list="Array.isArray(statistic.approvedAmountDeptList) ? statistic.approvedAmountDeptList : []"
                @search="onSearch"
              />
            </span>
          </div>
          <div
            class="value"
          >{{ typeof statistic.approvedAmount === 'number' ? statistic.approvedAmount.toLocaleString() : '--' }}</div>
        </div>
        <div class="item">
          <div class="title">
            <span>本年已生效(万)</span>
            <span class="extra">
              <MonthList
                title="本年已生效"
                :list="Array.isArray(statistic.effectAmountCurrentYearDeptList) ? statistic.effectAmountCurrentYearDeptList : []"
                @search="onSearch"
              />
            </span>
          </div>
          <div
            class="value"
          >{{ typeof statistic.effectAmountCurrentYear === 'number' ? statistic.effectAmountCurrentYear.toLocaleString() : '--' }}</div>
        </div>
        <div class="item">
          <div class="title">
            <span>累计已生效(万)</span>
            <span class="extra">
              <MonthList
                title="累计已生效"
                :list="Array.isArray(statistic.effectAmountDeptList) ? statistic.effectAmountDeptList : []"
                @search="onSearch"
              />
            </span>
          </div>
          <div
            class="value"
          >{{ typeof statistic.effectAmount === 'number' ? statistic.effectAmount.toLocaleString() : '--' }}</div>
        </div>
      </div>

      <Padding />

      <a-form-model :model="form" :colon="false" layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="24">
            <a-form-model-item>
              <a-input placeholder="设计编号" v-model="form.code" style="width: 150px"></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input placeholder="合同名称" v-model="form.name" style="width: 150px" />
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-model="form.productionUnitId"
                style="width: 150px"
                placeholder="责任部门"
              >
                <a-select-option
                  v-for="item in productionDepartmentList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{
                  item.uniqueName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-date-picker style="width: 150px" placeholder="申请时间" v-model="form.applyStartTime" />
            </a-form-model-item>

            <a-form-model-item>
              <a-date-picker
                style="width: 150px"
                placeholder="生效时间"
                v-model="form.effectStartTime"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-select placeholder="状态" v-model="form.status" style="width: 150px">
                <a-select-option
                  v-for="item in contract_amount_change_status"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
                total,
                current,
                pageSize,
                showTotal: (total) => `共 ${total} 条记录`,
            }"
        childrenColumnName="projectList"
      >
        <a-table-column title="#" width="40px" fixed="left">
          <template slot-scope="text">
            <span
              v-if="text.isProject"
              class="iconfont icon-md-return-right"
              style="font-size: 12px; color: #999;"
            ></span>
          </template>
        </a-table-column>
        <a-table-column title=" 设计编号" width="120px" data-index="code" fixed="left"></a-table-column>
        <a-table-column title="合同名称" data-index="name"></a-table-column>
        <a-table-column title="生产进度" align="center">
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.progress }}%</span>
          </template>
        </a-table-column>

        <a-table-column title="原合同金额(万元)" align="right">
          <template slot-scope="text">
            <Money :money="text.amountOld" />
          </template>
        </a-table-column>
        <a-table-column title="变更后合同金额(万元)" align="right">
          <template slot-scope="text">
            <Money :money="text.amountNew" />
          </template>
        </a-table-column>

        <a-table-column title="责任部门" data-index="productionUnitName"></a-table-column>

        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="contract_amount_change_status" :dictValue="text.status" />
          </template>
        </a-table-column>

        <a-table-column title="申请时间" data-index="applyTime" align="center"></a-table-column>
        <a-table-column title="生效时间" data-index="effectTime" align="center"></a-table-column>

        <a-table-column align="right" title="操作" width="160px" fixed="right">
          <template slot-scope="text">
            <a-space v-if="!text.isProject">
              <a
                href="#"
                v-if="$getPermission($route.path + '/change') && text.status === 'approved'"
                @click.prevent="effect(text)"
              >生效</a>
              <a href="#" @click.prevent="openDetail(text)">详情</a>

              <!-- <a
                href="#"
                v-if="$getPermission($route.path + '/change') && text.status === 'approved'"
                class="danger"
                @click.prevent="deleteText(text)"
              >作废</a>-->
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>
  
<script>
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import { mapGetters } from "vuex";

import request from "@/api/request";

import MonthList from "./components/month-list.vue";

function fetchStatistic() {
  return request({
    url: "/project-service/contract/change/sum",
    method: "post"
  });
}

function fetchList(data) {
  return request({
    url: "/project-service/contract/change/list",
    method: "post",
    data
  });
}

function changeStatus(data) {
  return request({
    url: "/project-service/contract/change/changeStatus",
    method: "post",
    data
  });
}

export default {
  mixins: [watermark, organization],

  components: {
    MonthList
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      statistic: {}
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    contract_amount_change_status() {
      return this.findDataDict("contract_amount_change_status");
    }
  },

  mounted() {
    fetchStatistic().then(res => {
      console.log("statistic", res);
      this.statistic = res || {};
    });
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      const {
        code,
        name,
        applyStartTime,
        effectStartTime,
        status,
        productionUnitId
      } = form;

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        code,
        name,
        status,
        productionUnitId,
        applyStartTime: applyStartTime
          ? applyStartTime.format("YYYY-MM-DD") + " 00:00:00"
          : undefined,
        applyEndTime: applyStartTime
          ? applyStartTime.format("YYYY-MM-DD") + " 23:59:59"
          : undefined,
        effectStartTime: effectStartTime
          ? effectStartTime.format("YYYY-MM-DD") + " 00:00:00"
          : undefined,
        effectEndTime: effectStartTime
          ? effectStartTime.format("YYYY-MM-DD") + " 23:59:59"
          : undefined
      })
        .then(res => {
          this.loading = false;
          if (res.pageData && Array.isArray(res.pageData.list)) {
            this.list = Object.freeze(
              res.pageData.list.map((item, index) => {
                return {
                  key: item.id + item.code + index + Math.random(),
                  ...item,
                  projectList: Array.isArray(item.projectList)
                    ? item.projectList.map((element, index) => {
                        return {
                          isProject: true,
                          key:
                            element.id +
                            element.designCode +
                            index +
                            Math.random(),
                          code: element.designCode,
                          ...element
                        };
                      })
                    : undefined
                };
              })
            );
            this.total = res.pageData.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onSearch(payload) {
      if (Array.isArray(payload)) {
        this.form = {};
        payload.forEach(item => {
          if (item.type === "applyDeptId") {
            this.form = {
              ...this.form,
              productionUnitId: item.value
            };
          }
        });
      }
      this.current = 1;
      this.getList();
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要作废吗？",
        onOk() {
          changeStatus({
            id: text.id,
            status: "invalid"
          }).then(() => {
            that.getList();
          });
        }
      });
    },

    effect(text) {
      const that = this;
      this.$confirm({
        title: "确认要生效吗？",
        onOk() {
          changeStatus({
            id: text.id,
            status: "effect"
          }).then(() => {
            that.getList();
          });
        }
      });
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    openDetail(item) {
      this.$router.push(this.$route.path + "/detail?id=" + item.id);
    }
  }
};
</script>


<style lang="less" scoped>
.card {
  display: flex;

  .item {
    border: 2px solid #f0f0f0;
    margin-right: 12px;
    padding: 12px;
    border-radius: 2px;
    min-width: 240px;
    position: relative;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .extra {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .value {
      font-size: 2em;
      color: #1890ff;
    }

    .setting {
      position: absolute;
      bottom: 12px;
      right: 12px;
      cursor: pointer;
    }
  }

  .active {
    border-color: #1890ff;
  }
}
</style>