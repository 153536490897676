<template>
  <span>
    <span @click="visible = true">
      <a-icon type="right" />
    </span>

    <a-modal :visible="visible" :title="title" :footer="null" @cancel="visible = false">
      <a-table bordered :data-source="list" :pagination="false" rowKey="applyDeptId">
        <a-table-column title="部门" data-index="applyDeptName" />
        <a-table-column title="金额(万)" align="right">
          <template slot-scope="text">
            <span
              style="color: #1890ff"
            >{{typeof text.amount === 'number' ? text.amount.toLocaleString() : 0}}</span>
          </template>
        </a-table-column>
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="search(text)">查询</a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      visible: false
    };
  },

  methods: {
    search(text) {
      this.visible = false;
      this.$emit("search", [
        {
          type: "applyDeptId",
          value: text.applyDeptId
        }
      ]);
    }
  }
};
</script>