import { mapState, mapGetters, mapActions } from 'vuex'

export default {


    computed: {
        ...mapState('organization', ['organizationList']),
        ...mapGetters("organization", ["productionDepartmentList"]),
    },

    mounted() {
        if (this.organizationList.length === 0) {
            this.getOrganizationList();
        }
    },

    methods: {
        ...mapActions("organization", ["getOrganizationList"]),
    }
}