export default {

    mounted() {
        const container = document.querySelector(".container");
        if (container) {
            this.setWatermark(container);
        }
    },

    methods: {
        setWatermark(node, color = '#f5f5f5') {
            const userStr = window.localStorage.getItem('user');
            if (!userStr) {
                return;
            }
            const user = JSON.parse(userStr);

            const now = new Date();
            const year = now.getFullYear();
            let month = now.getMonth() + 1;
            let date = now.getDate();
            let hours = now.getHours();
            let minutes = now.getMinutes();
            if (month < 10) {
                month = '0' + month
            }
            if (date < 10) {
                date = '0' + date;
            }
            if (hours < 10) {
                hours = '0' + hours;
            }
            if (minutes < 10) {
                minutes = '0' + minutes
            }

            const dateTime = `${year}-${month}-${date} ${hours}:${minutes}`

            const can = document.createElement("canvas");
            can.width = 260;
            can.height = 140;

            const cans = can.getContext("2d");
            cans.rotate((-25 * Math.PI) / 180); // 水印旋转角度
            cans.font = "12px Microsoft YaHei";
            cans.fillStyle = color;
            // cans.fillStyle = '#f00'
            cans.textAlign = "center";
            cans.textBaseline = "Middle";
            cans.fillText("NJMD " + user.userName, can.width / 2, can.height); // 水印在画布的位置x，y轴
            cans.fillText(dateTime, can.width / 2, can.height + 25);

            const image = can.toDataURL("image/png");
            node.style.backgroundImage = `url(${image})`;
        }
    }
}

